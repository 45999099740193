import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { CreateDirectDebitMandateCommand } from "../models/directDebit/createDirectDebitMandateCommand";

interface directDebitState {
    reference: string | null;
    submitDirectDebitDetailsStatus: ApiRequestStatus;
}

const initialState: directDebitState = {
    reference: null,
    submitDirectDebitDetailsStatus: ApiRequestStatus.Idle
}

export const submitDirectDebitDetailsAsync = createAsyncThunk<string, CreateDirectDebitMandateCommand>(
    'directDebitSlice/submitDirectDebitDetailsAsync',
    async (createDirectDebitMandateCommand, thunkAPI) => {
        try {
            return agent.DirectDebit.submitDirectDebitDetailsAsync(createDirectDebitMandateCommand);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const directDebitSlice = createSlice({
    name: 'directDebitSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(submitDirectDebitDetailsAsync.pending, (state) => {
            state.submitDirectDebitDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(submitDirectDebitDetailsAsync.fulfilled, (state, action) => {
            state.reference = action.payload;
            state.submitDirectDebitDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(submitDirectDebitDetailsAsync.rejected, (state) => {
            state.submitDirectDebitDetailsStatus = ApiRequestStatus.Rejected;
        });
    })
})
