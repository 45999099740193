import axios, { AxiosResponse } from "axios";
import { history } from "../..";
import AppToasterWithErrorMessages from "../components/AppToasterWithErrorMessages";
import { CreateManualPaymentDTO } from "../models/manualPayment/createManualPaymentDTO";
import { CreateDirectDebitMandateCommand } from "../models/directDebit/createDirectDebitMandateCommand";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use(
    config => {
        return config;
    });

axios.interceptors.response.use(async response => {
    return response;
}, (error: any) => {
    const { data, status } = error.response!;
    switch (status) {
        case 400:
            if (data.errors) {
                const modelStateErrors: string[] = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modelStateErrors.push(data.errors[key])
                    }
                }
                AppToasterWithErrorMessages(modelStateErrors);
                throw modelStateErrors.flat();
            }
            break;
        case 401:
            history.push({ pathname: '/logout' });
            break;
        case 404:
        case 500:
            history.push({ pathname: '/server-error' });
            break;
        default:
            break;
    }
    return Promise.reject(error.response);
})

const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    getConfig: (url: string, config: {}) => axios.get(url, config).then(responseBody),
    post: (url: string, body: {}, config: {} = {}) => axios.post(url, body, config).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
}

const documents = {
    getFiles: (id: string) => requests.get(`Documents/${id}`),
    getFile: (id: string, name: string) => requests.getConfig(`Documents/${id}/${name}`, {
        responseType: 'blob',
    }),
    saveFile: (data: FormData) => requests.post(`Documents`, data, {
        headers: {
            "content-type": "multipart/form-data"
        },
    }),
    deleteFile: (id: string, name: string) => requests.delete(`Documents/${id}/${name}`),
}

const referenceData = {
    documentTypes: () => requests.get(`ReferenceData/DocumentTypes`),
    fundsReferenceDataAsync: () => requests.get(`ReferenceData/Funds`),
    referenceDataAsync: () => requests.get(`ReferenceData`)
}

const manualPayment = {
    submitManualPayment: (manualPaymentDTO: CreateManualPaymentDTO) => requests.post(`Payment`, manualPaymentDTO)
}

const famDetails = {
    getFamDetailsAsync: (personId: string) => requests.get(`Fam/${personId}`)
}

const person = {
    getPersonalDetailsAsync: (personId: string) => requests.get(`Person/${personId}`)
}

const DirectDebit = {
    submitDirectDebitDetailsAsync: (createDirectDebitMandateCommand: CreateDirectDebitMandateCommand) =>
        requests.post(`DirectDebitMandate/FromBasket`, createDirectDebitMandateCommand)
}

const agent = {
    documents,
    referenceData,
    manualPayment,
    famDetails,
    person,
    DirectDebit
}

export default agent;